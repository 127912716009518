<template>
  <main class="px-2 md:px-4 py-6 flex flex-col gap-6">
    <h4
      class="capitalize md:uppercase md:text-sm md:text-left text-xs font-medium text-headerText"
    >
      Book Keeping / Inventory
    </h4>

    <!-- //// Search and Filter ///// -->

    <div class="flex flex-col md:flex-row items-center justify-between gap-4">
      <div class="flex-grow w-full">
        <easiSearch
          placeholder="Search inventory "
          v-model="searchInput"
          @search="handleSearch"
          :suggestion="false"
        />
      </div>

      <div class="flex items-center gap-4">
        <InventoryFilter v-model:loading="loading" />
        <!-- <easiButton
          rounded="2xl"
          variant="outlined"
          color="gray"
          class="bg-white text-dark-600 gap-2"
        >
          <i class="pi pi-download text-sm" />
          Download</easiButton
        > -->
        <easiButton
          @click="$router.push({ name: 'AddInventory' })"
          class="gap-2"
        >
          <i class="pi pi-plus text-sm" /> Add New Item</easiButton
        >
      </div>
    </div>

    <!-- //// Stats Card ///// -->
    <div
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
    >
      <InventoryCard v-for="item in stats" :key="item" :data="item" />
    </div>

    <!-- //// Table ///// -->
    <ListInventory v-model:loading="loading" />

    <easiRoundLoader v-if="loadingModal" />
  </main>
</template>

<script setup>
import InventoryFilter from "@/components/BookKeeping/Inventory/InventoryFilter.vue";
import InventoryCard from "@/components/BookKeeping/Inventory/InventoryCard";
import ListInventory from "@/components/BookKeeping/Inventory/ListInventory";
import { computed, onMounted, ref, watch } from "vue";
import { useDataStore } from "@/stores/data";
import {
  getInventoryItems,
  getInventoryStats,
} from "@/services/book-keeping/inventory";
import { getCategories } from "@/services/book-keeping/settings";
import { debouncedValue } from "@/composable/debouncedValue";

const store = useDataStore();

const loading = ref(false);
const loadingModal = ref(false);
const searchInput = ref("");
const debounceSearch = debouncedValue(searchInput);

const inventory = computed(() =>
  store.getBookingInventory ? store.getBookingInventory.docs : []
);

const stats = computed(() => {
  const data = store.getBookingInventoryStats || null;

  console.log(data);
  return [
    {
      title: "Item Count",
      value: data ? data.totalItemsInStock.toLocaleString() : 0,
    },
    {
      title: "Number of items sold",
      value: data ? data.totalItemsSold.toLocaleString() : 0,
    },
    {
      title: "Lowest quantity in stock",
      value: data
        ? data.lowestStockQuantity && data.lowestStockQuantity.name
        : "N/A",
      url: data
        ? data.lowestStockQuantity && data.lowestStockQuantity.image
        : "",
    },
    {
      title: "Most sold item",
      value: data ? data.mostSoldStock && data.lowestStockQuantity.name : "N/A",
      url: data ? data.mostSoldStock && data.lowestStockQuantity.image : "",
    },
  ];
});

const handleSearch = async () => {
  try {
    searchInput.value ? (loading.value = true) : (loadingModal.value = true);
    await getInventoryItems({ search: searchInput.value });
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
    loadingModal.value = false;
  }
};

watch(debounceSearch, () => {
  handleSearch();
});

onMounted(async () => {
  try {
    loadingModal.value = !inventory.value.length;
    getCategories();
    await getInventoryItems();
    await getInventoryStats();
  } catch (error) {
    console.log(error);
  } finally {
    loadingModal.value = false;
  }
});
</script>

<style></style>
